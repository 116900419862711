
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import StammdatenForm from './_StammdatenForm.vue';

import { useStore } from 'vuex';

export default defineComponent({
  components: {
    StammdatenForm,
    // GradingForm,
  },
  setup() {
    const router = useRouter();
    const id = computed(() => parseInt(router.currentRoute.value.params.id as string));
    const store = useStore();
    const name = ref('');

    const saving = ref(false);

    const onSave = (user: any) => {
      if (!saving.value) {
        saving.value = true;
        store
          .dispatch('updateWorker', { id: id.value, user })
          .then((res: any) => {
            store.dispatch('addNotification', { message: 'Mitarbeiter:innen gespeichert' });
            saving.value = false;
            //router.push({ name: 'SkillsMitarbeiterList' });
          })
          .catch((err: any) => {
            saving.value = false;
            store.dispatch('addNotification', { message: err.response.data.message, type: 'error', lifetime: 5000 });
          });
      }
    };

    store.dispatch('fetchUserData', id.value).then((res: any) => {
      name.value = `${res.firstname} ${res.lastname}`;
    });

    return {
      id,
      onSave,
      name,
    };
  },
});
